import React from 'react';
import styles from './elems.module.css';
import FullInfoBlock from '../../../../elements/fullInfoBlock/fullInfoBlock';
import HalfInfoBlock from '../../../../elements/halfInfoBlock/halfInfoBlock';
import { dateTransform, getOwnershipType, isMongo } from '../../../../../utils/check';
import InfoBlockСollapser from '../../../../elements/infoBlockСollapser/infoBlockСollapser';
import AssetDecisions from './decisions';
import AssetItem from './assetItem';
import AssetOrganizations from './organizations';
import AssetDisqualifiedBids from './disqualifiedBids';
import RelatedEntities from '../../../announcement/view/elems/relatedEntities';
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';
import { setAssetDocTypes, setAssetDocTypesLP } from '../../create/elems/documents/utils';
import Button from '../../../../elements/buttons/button';
import AssetCancellation from './cancellation';

const AssetGeneral = ( { data } ) => {

    let tmpSaleCond = 'Не визначено'
    if (data.saleCondition === 'yes') tmpSaleCond = 'Так'
    if (data.saleCondition === 'no') tmpSaleCond = 'Ні'
    const generalInfo = <div className={styles.infoBlock + ' ' + styles.generalInfo}>
                                {/* <div className={styles.infoBlockTitle}>
                                    <h3>Загальна інформація</h3>
                                </div> */}
                                    <div className={styles.infoBlockContent}>
                                        {!!data?.title &&
                                            <FullInfoBlock
                                                title='Назва об`єкту реєстра малої приватизації'
                                                data={ isMongo(data.title)}
                                            />
                                        }
                                        {!!data?.description &&
                                            <FullInfoBlock
                                                title='Опис об`єкту'
                                                data={ isMongo(data.description)}
                                            />
                                        }
                                        <FullInfoBlock
                                            title='Тип власності об’єкту малої приватизації'
                                            data={ getOwnershipType(data.ownershipType) }
                                        />
                                        <HalfInfoBlock
                                            title='Дата публікації'
                                            data={ dateTransform(data.datePublished, 'time') }
                                        />
                                        <HalfInfoBlock
                                            title='Дата останньої зміни'
                                            data={ dateTransform(data.dateModified, 'time') }
                                        />
                                        <HalfInfoBlock
                                            title='Наявність умов продажу'
                                            data={tmpSaleCond}
                                        />
                                    </div>
                            </div>

    function docType(doc){
        const docTypeList = data.assetId.indexOf('RAL') === -1 ? setAssetDocTypes( null, true ) : setAssetDocTypesLP( null, true )
        for (let key in docTypeList) {
            if ( docTypeList[key].value === doc.documentType ) return docTypeList[key].name
        }
        return null
    }

    return (
            <div className={styles.blockWrap}>
                <div>
                    {/* <Button onClick></Button> */}
                </div>
                { generalInfo }
                <InfoBlockСollapser 
                    blockTitle={data?.assetId?.indexOf('RAL') === -1
                        ? 'Рішення органу приватизації про затвердження умов продажу'
                        : 'Рішення про приватизацію об\'єкта'
                    }
                    content={ <AssetDecisions data={data.decisions} /> }
                />
                <InfoBlockСollapser 
                    blockTitle={'Перелік об`єктів малої приватизації'}
                    content={ <div style={{display: 'flex', flexDirection: 'column', gap:'20px'}}>
                        { data.assetItems.map( item => <AssetItem data={item} key={item.id} /> ) }
                            </div>}
                />
                <InfoBlockСollapser 
                    blockTitle={ 'Організації задіяні у керуванні об`єктом' }
                    content={ <AssetOrganizations data={data} /> }
                />
                { !!data?.disqualifiedBids && data?.disqualifiedBids.length > 0 &&
                    <InfoBlockСollapser 
                        blockTitle={ 'Список учасників, які були дискваліфіковані в будь якому пов`язаному аукціоні' }
                        content={ <AssetDisqualifiedBids data={ data.disqualifiedBids } /> }
                    />
                }
                { !!data?.relatedEntities && data.relatedEntities.length > 0 &&
                    <InfoBlockСollapser 
                        blockTitle={ 'Список пов`язаних сутностей' }
                        content={ <RelatedEntities data={ data.relatedEntities } /> }
                    />
                }
                { ( !!data?.cancellations && data.cancellations.length > 0 ) && 
                    data.cancellations.map( i => <AssetCancellation data={ i } key={ i.id } />)
                }
                { data?.documents &&
                    <InfoBlockСollapser 
                        blockTitle={ <>Документи { data.documents.length > 0 && `( ${data.documents.length} )` }</> }
                        content={   
                                <div style={{display: 'flex', gap: '10px', flexFlow: 'column', width: '100%'}}>
                                    {
                                        data.documents.length > 0 
                                    ?
                                        // data.documents.map( doc => <AssetDocItem data={doc} key={doc.id} /> )
                                        data.documents.map( doc => <ViewDocItem data={doc} key={doc.id} 
                                            specs={{
                                                objId: data.id,
                                                docType: docType(doc),
                                                historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/registry/asset/${data.id}/document/${doc.id}/history`
                                            }}/> )
                                    : 'Документів не знайдено'
                                    }
                                </div>
                        }
                    />
                }
            </div>
        )
}

export default AssetGeneral